
.textbreak{
  display: block;
}

.common-spacing {
  padding: 80px 15px;
}

/* =========================== Start less than 1440px ========================================= */
@media(max-width: 1440px) {

}
/* =========================== End less than 1440px ========================================= */



/* =========================== Start less than 1200px ========================================= */
@media(max-width: 1200px) {
  .textbreak{
    display: inline;
  }
  .section-title h2, .newTitle , .section-title .headingHighLight{
    font-size: 32px;
  }
  .hero-area.two .hero-text h1 {
    font-size: 56px;
  }
  ul.infoGraphic li .content h2{
    font-size: 18px;
  }
  .pxp-services-1-item-title{
    font-size: 20px;

  }
  .pxp-services-1-item-text{
    margin-bottom: 26px;
  }
  .pxp-services-1-item{
    min-height: 450px;
    padding: 20px 15px;
  }
}
/* =========================== End less than 1200px ========================================= */

/* =========================== Start less than 1023px ========================================= */
@media(max-width: 1023px) {
  .section-title h2, .newTitle , .section-title .headingHighLight{
    font-size: 28px !important;
    line-height: 36px;
    margin-bottom: 13px !important;
}

}
/* =========================== End less than 1023px ========================================= */


/* =========================== Start less than 991px ========================================= */
@media(max-width: 991px) {
  .common-spacing {
    padding: 50px 15px;
}

  .hero-area .hero-video-head{
    margin-top: 0;
    display: block;
  }
  .hero-area .hero-inner{
    min-height: 650px;
  }
 
.section-title h2, .newTitle{
  margin-bottom: 40px;
}
.fast-eff > div:last-child img{
  margin-top: 40px;
}
.job-category .cat-head {
  padding: 26px 32px;
}
.job-category .single-cat {
  padding: 22px 15px;
}

.about-us .content-right .single-list {
  padding-left: 57px;
}
.about-us .content-right .single-list .ques_no {
  font-size: 16px;
    height: 30px;
    width: 30px;
    line-height: 28px;
}
.footer .footer-middle {
  padding-bottom: 50px;
  padding-top: 0px;
}
.footer .footer-bottom .inner {
  padding-bottom: 0;
}
.footer .single-footer h3{
  margin-bottom: 20px !important;
}
.footer .f-link ul li{
  margin-bottom: 7px;
}
.footer .single-footer ul {
  padding-left: 0;
}
.client-logo-section .client-logo-wrapper .client-logo-carousel{
  flex-wrap: wrap;
}
.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  flex: 0 0 20%;
}

.header .navbar-nav li .sub-menu{
  left: 0;
}

.navbar-collapse {
  height: 432px;
}
ul.infoGraphic li{
  flex: 0 0 49%;
}
.pxp-services-1-item{
  min-height: 430px;
}
.hero-area.two .hero-text h1 {
  font-size: 50px;
}
.resumeCard{
  padding: 30px;
}
.resumeCard.shortlistCard img {
  margin-bottom: 20px;
}
.testimonidal-man img {
  width: 50%;
  right: -40%;
}
.testimonials.two .carousel-control-prev, .testimonials.two .carousel-control-next {
  display: none;
}
.floading-appSection h4.heading-color{
  font-size: 22px !important;
}
.hiredResource-Section{
  background-size: 56%;
}
.floading-appSection{
  margin-bottom: 50px;
}
.bgcircleshape {
  position: unset;
}
.perfectway-section .resumeCard.shortlistCard > div {
  flex-direction: column-reverse;
}
.perfectway-section .resumeCard {
  padding-top: 0;
}
.perfectway-section .resumeCard.shortlistCard > div
.bgcircleshape.two.three img {
  width: 60%;
}
.contact-us .contant-inner-title {
  margin-bottom: 35px;
  padding-top: 22px;
}
}
/* =========================== End less than 991px ========================================= */

/* =========================== Start less than 767px ========================================= */
@media(max-width: 767px) {
  .hero-area .job-search-wrap-two {
    margin-top: 0!important;
}
.hero-area .hero-inner {
  min-height: 620px;
}
.common-spacing {
  padding: 50px 15px;
}
.section-title h2, .section-title h2{
  margin-bottom: 30px;
  font-size: 24px;
}

.hero-area .hero-text p, p {
  font-size: 16px !important;
}

.hero-area .hero-text h1 {
  font-size: 26px;
}
.apply-process .process-item {
  margin-bottom: 0px;
  
}
section.apply-process > div > div > div {
  margin-bottom: 30px;
}
section.apply-process > div > div > div:last-child {
  margin-bottom: 0px;
}
.job-category .single-cat h3 {
  font-size: 14px;
}
.job-category .single-cat i {
  font-size: 19px;
  height: 40px;
  width: 40px;
  line-height: 42px;
}
.footer .footer-bottom .inner {
 padding: 0;
}
.footer .footer-bottom .right ul {
 margin-bottom: 0;
}
.footer .footer-bottom .inner .right ul li a {
  font-size: 14px;
}
.footer .f-link ul li a {
  font-size: 14px;
}
.footer .footer-middle {
  padding-bottom: 30px !important;
  padding-top: 0px !important;
}
.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  flex: 0 0 23%;
}
.section-title h2, .newTitle, .section-title .headingHighLight {
  font-size: 24px !important;
}
section.project-section {
  background-size: 52%;
  background-position: 100% 136px;
  
}
.contact-us .form .form-group input {
  height: 40px;
  line-height: 51px;
  font-size: 14px;
}
.contact-us .form .form-group textarea {
  height: 153px;
  font-size: 14px;
}
.navbar-collapse{
  height: 500px;
}
ul.infoGraphic li{
  flex: 0 0 100%;
}
.floatingbtn-scroll {
  bottom: 30px;
}
.scroll-top{
  width: 38px;
  height: 38px;
}
.pxp-services-1-item-title{
  margin-top: 60px;
}
.pxp-services-1-item-text{
  min-height: auto;
}
.pxp-services-1-item-icon img, .elementor .pxp-services-1-item-icon img{
  height: 118px;
}
.pxp-services-1-item {
  min-height: 390px;
}
.hero-area.two .hero-text {
  margin-top: 172px;
}
.floading-btn img {
  width: 48px;
}
.starter__content ul li > div {
  align-items: center;
}
.starter__text .h4, .starter__text h4 {
  font-size: 16px;
}
.section-title .headingHighLight{
  margin-bottom: 0 !important;
}
.starter__icon i{
  height: 55px;
  width: 55px;
}
.starter__icon i{
  font-size: 20px;
}
.section-title h2, .newTitle{
  padding-bottom: 0;
}
.testimonials.two .single-testimonial .quote i {
  font-size: 34px;
}
.testimonials.two .single-testimonial .bottom .name {
  font-size: 23px;
}
.mobile-floatOne img {
  left: 75%;
  transform: rotate(330deg);
  width: 16%;
}
.mobile-floatwo img {
  right: 0;
  width: 16%;
}
.floading-appSection h4.heading-color{
  padding-right: 46px;
  font-size: 20px !important;
}
.floading-appSection {
  margin-bottom: 0;
}
.resumeCard h3 {
  font-size: 16px;
  margin-bottom: 30px;
}
.perfectway-section .resumeCard.shortlistCard > div
.bgcircleshape.two.three img {
    width: 83%;
}
}
/* =========================== End less than 767px ========================================= */

/* =========================== Start less than 576px ========================================= */
@media(max-width: 576px) {
  h5, .h5, .h5 {
    font-size: 14px !important;
  }
  .section-title h2, .section-title.noline {
    font-size: 22px;
}
  .fe-ta-container h6 {
    font-size: 14px;
    line-height: 21px;
}
.missionSec .main-img img {
  width: 23px;
  height: 23px;
}
.missionSec {
  margin-bottom: 16px;
}
.common-spacing {
  padding: 40px 15px;
}
.single-list .list-bod  h5 {
  font-size: 14px  !important;
}
.about-us .content-right .single-list {
  margin-bottom: 26px;
}
.section-title.noline h2 {
  line-height: 29px;
}
.about-us .content-left .media-body{
  padding: 18px 13px;
}
.about-us .content-left .media-body i {
  height: 30px;
  width: 30px;
  line-height: 33px;
  font-size: 13px;
}
.footer .f-about .contact-address li span{
  font-size: 14px;
}
.footer .f-about .contact-address li a{
  font-size: 14px;
}
.footer .single-footer {
  margin-top: 16px;
}
.footer .footer-social {
  margin-top: 16px;
}
.subheadingTwo h2 {
  margin-bottom: 20px;
}
.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  flex: 0 0 40%;
}
section.project-section {
  padding-bottom: 55px !important;
  background-position: 100% 230px;
}
.contact-us .form-main {
  padding: 30px 21px;
}

.contact-us .single-info ul li , .contact-us .single-info ul li a{
  font-size: 14px;
}
.contact-us .single-info i{
  font-size: 15px;
}

.navbar-collapse {
  height: 438px;
}
.pxp-services-1-item-title {
  margin-top: 50px;
}
.pxp-services-1-item-title {
  font-size: 18px;
}
.pxp-services-1-item-text{
  font-size: 16px;
}
.hero-area.two .hero-text h1 {
  font-size: 42px;
  line-height: 44px;
}
.client-logo-section .slick-dots{
  bottom: -39px;
  line-height: 0;
}
.contact-us .single-info {
  padding-left: 26px;
}
}
/* =========================== End less than 576px ========================================= */